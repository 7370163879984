import React, { useEffect, useState } from 'react'

import { i18nPath, I18NCommon } from 'utils/i18nHelpers'

import EmployeeMiniCardLink from 'components/common/employeeMiniCardLink'
import { Button } from 'components/common/buttons'
import EmployeeSearch from 'components/form_fields/employeeSearch'
import journeyBlueprintSlice from 'redux/slices/journeyBlueprints'
import { useDispatch } from 'react-redux'
import useApi from 'components/common/hooks/useApi'
import API from 'services/api'
import CheckBox from 'components/common/buttons/checkBox'

const I18N = i18nPath('views.admin.journeys.journey_blueprints.settings_modal')

const GeneralSettings = ({
  id,
  creator,
  journeyAdmin,
  workingCopy,
  openDeleteBlueprintModal,
  openDuplicateBlueprintModal,
  updateWorkingCopy,
}) => {
  const dispatch = useDispatch()
  const [journeyEditors, setJourneyEditors] = useState([])

  const [fetchCollaborators, { isLoading: isLoadingCollaborators }] = useApi(API.users.simpleFetchAll, {
    onSuccess: data => setJourneyEditors(data),
  })

  const { collaboratorIds, notifyCollaboratorsOnError, rehireRetake } = workingCopy

  useEffect(() => {
    if (!_.isEmpty(collaboratorIds)) {
      fetchCollaborators(collaboratorIds)
    }
  }, [])

  const journeyAdminChange = (journeyAdmin) => {
    const journeyBlueprintParams = {
      id,
      journeyAdminId: journeyAdmin?.id,
    }

    dispatch(journeyBlueprintSlice.asyncActions.admin.update(journeyBlueprintParams))
  }

  const journeyEditorChange = (journeyEditors) => {
    setJourneyEditors(journeyEditors)
    updateWorkingCopy({ collaboratorIds: journeyEditors.map(user => user.id) })
  }

  return (
    <div className='GeneralJourneyBlueprintSettings pb-2'>
      {!_.isEmpty(creator) && (
        <div className='w-100 w-lg-25 mb-3'>
          <div className='mb-2'>{I18N('created_by')}</div>
          <div className='bordered'>
            <EmployeeMiniCardLink employee={creator} padding={2} targetBlank />
          </div>
        </div>
      )}

      <div className='mb-5 mt-5'>
        <div className='mb-1'>{I18N('designate_journey_admin')}</div>
        <div className='text-secondary text-small mb-3'>{I18N('designate_journey_admin_help_text')}</div>
        <EmployeeSearch
          placeholder={I18N('designate_journey_admin_placeholder')}
          onChange={journeyAdminChange}
          selectedEmployee={journeyAdmin}
          isClearable={false}
        />
      </div>

      <div className='mb-5 mt-5'>
        <div className='mb-1'>{I18N('designate_journey_editors')}</div>
        <div className='text-secondary text-small mb-3'>{I18N('designate_journey_editors_help_text')}</div>
        <EmployeeSearch
          placeholder={I18N('designate_journey_editors_placeholder')}
          onChange={journeyEditorChange}
          selectedEmployees={journeyEditors}
          isClearable
          isMulti
          disabled={isLoadingCollaborators}
        />

        <CheckBox
          id='notify-collaborators-on-error'
          className='mt-1'
          checked={notifyCollaboratorsOnError}
          onChange={checked => updateWorkingCopy({ notifyCollaboratorsOnError: checked })}
          label={I18N('notify_collaborators_on_error')}
        />
      </div>

      <CheckBox
        id='rehire-retake'
        className='mt-1'
        checked={rehireRetake}
        onChange={checked => updateWorkingCopy({ rehireRetake: checked })}
        label={I18N('rehire_retake')}
        helpText={I18N('rehire_retake_help_text')}
      />

      <div className='mb-5 mt-5'>
        <div className='mb-1'>{I18N('duplicate_journey')}</div>
        <div className='text-secondary text-small mb-3'>{I18N('duplicate_journey_help_text')}</div>
        <Button variant='secondary' onClick={openDuplicateBlueprintModal}>
          {I18NCommon('duplicate')}
        </Button>
      </div>

      <div className='mb-4'>
        <div className='mb-1'>{I18N('delete_journey')}</div>
        <div className='text-secondary text-small mb-3'>{I18N('delete_journey_help_text')}</div>
        <Button variant='secondary-danger' onClick={openDeleteBlueprintModal}>
          {I18NCommon('delete')}
        </Button>
      </div>
    </div>
  )
}

export default GeneralSettings
